/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
	.view-card .card-content {
		max-width: calc(100% - 44px);
	}
}

/* Wide Mobile Layout: 480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}